import ConnectButton from 'components/header/ConnectButton';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

const HeroSection = () => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <div className="flex flex-col justify-center items-center py-10">
      {/* <NoneData /> */}
      <h3>Revoke Token Approvals</h3>
      <p className="p-4">Review and revoke your token approvals for any dApp.</p>
      <ConnectButton
        style="primary"
        size="lg"
        className="font-bold mx-auto main-bg border-none rounded-full hover:bg-teal-700"
        text={'Get Started'}
      />
    </div>
  );
};

export default HeroSection;
