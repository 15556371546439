
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import HeroSection from 'components/landing/HeroSection';
import LandingLayout from 'layouts/LandingLayout';
import { defaultSEO } from 'lib/next-seo.config';
import type { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
const Landing: NextPage = () => {
    const { t } = useTranslation();
    return (<>
      <NextSeo {...defaultSEO} title={t('common:meta.title')} description={t('common:meta.description')}/>
      <LandingLayout>
        <div className="rounded-3xl bg-white max-w-7xl w-full mx-auto px-4 py-4 lg:px-8 mt-10">
          <HeroSection />
          {/* <HowTo /> */}
          {/* <WhyRevokeCash /> */}
          {/* <LandingPageFaq /> */}
          {/* <Sponsors /> */}
        </div>
      </LandingLayout>
    </>);
};
export default Landing;

    async function __Next_Translate__getStaticProps__18d5ed9fd1d__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18d5ed9fd1d__ as getStaticProps }
  